import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import firebase from 'firebase/compat/app'
import { GetServerSideProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Router from 'next/router'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import getApolloClient from 'src/apollo-client'
import { BorderRadius, Colors } from 'src/constants/theme'
import useLogger from 'src/context/LoggerProvider/useLogger'
import verifyAuthenticatedUser from 'src/firebase/firebaseApolloAdmin'
import { mapToQueryString } from 'src/utils/getLoginDestination'
import loggerServer from 'src/utils/loggerServer'
import { LoaderContainer } from '../src/components/Loaders/Loader'
import Login from '../src/components/Login'
import { useAuthProvider } from '../src/context/AuthProvider'
import { useLoaderProvider } from '../src/context/LoaderProvider'
import { RedirectUrls } from '../src/types/types'

const Footer = dynamic(() => import('src/components/Footer'))
const LandingNav = dynamic(() => import('src/components/LandingNav'))

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 50px;
  margin-inline: auto;
  min-height: calc(100vh - 100px);
`

const StyledLoginBox = styled(Box)`
  width: 100%;
  padding: 30px;
  max-width: 550px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    border: 1px solid ${Colors.black300};
    border-radius: ${BorderRadius.xLarge}px;
  }
`

const { title, description, image, url } = {
  url: 'https://www.spotme.mx/login',
  title: 'SpotMe | Iniciar sesión',
  description: 'Ingresa a SpotMe. Primer marketplace de alojamiento en México',
  image:
    'https://www.spotme.mx/_next/image?url=%2Fimages%2Fcover.png&w=1200&q=100'
}

type IProps = {
  redirect: string | null
  email: string | null
  backoff: number | null
}

const Page: NextPage<IProps> = ({ redirect, email, backoff }) => {
  const { isAuthenticated, loading } = useAuthProvider()
  const { redirecting, setRedirecting } = useLoaderProvider()
  const { enqueueSnackbar } = useSnackbar()
  const logger = useLogger()

  useEffect(() => {
    setRedirecting(loading || (!!backoff && isAuthenticated))
  }, [backoff, isAuthenticated, loading, setRedirecting])

  useEffect(() => {
    Router.prefetch(redirect ?? RedirectUrls.Dashboard)
    if (!isAuthenticated) return
    if (backoff) return

    setRedirecting(true)
    Router.push(redirect ?? RedirectUrls.Dashboard)
  }, [isAuthenticated, redirect, setRedirecting, backoff])

  useEffect(() => {
    if (loading || !backoff || !isAuthenticated) return

    setTimeout(
      () => {
        Router.push(redirect ?? RedirectUrls.Home)
      },
      1000 * (backoff ?? 1)
    )
  }, [backoff, isAuthenticated, loading, redirect])

  useEffect(() => {
    const auth = firebase.auth()
    if (email && auth.isSignInWithEmailLink(window.location.href)) {
      auth
        .signInWithEmailLink(email, window.location.href)
        .then(() => {
          Router.push(redirect ?? RedirectUrls.Home)
        })
        .catch((error) => {
          logger.error('Error sign in with email.', error)
          enqueueSnackbar('¡Hubo un error! Por favor intenta de nuevo.', {
            variant: 'error'
          })
        })
    }
  }, [email, redirect, enqueueSnackbar, logger])

  const loadingRedirect = !!redirect && loading

  return (
    <div style={{ height: '100%' }}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="icon" href="/logos/Logo_Grande.ico" />
        <link rel="canonical" href={url} />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:locale:alternate" content="es_ES" />
        <meta property="og:url" content={url} />
        <meta property="fb:app_id" content="1664394843756674" />
        <meta property="og:image" content={image} itemProp="image" />
        <meta property="og:description" content={description} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@spotmemx" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Head>

      <main style={{ height: '100%' }}>
        <LandingNav />
        <LoaderContainer loading={redirecting || loadingRedirect} fullScreen>
          <StyledContainer>
            <StyledLoginBox>
              <Login />
            </StyledLoginBox>
          </StyledContainer>
        </LoaderContainer>
        <Footer />
      </main>
    </div>
  )
}

export default React.memo(Page)

// eslint-disable-next-line @typescript-eslint/ban-types
export const getServerSideProps: GetServerSideProps<IProps | {}> = async (
  context
) => {
  const { locale, query } = context
  const translations = await serverSideTranslations(locale ?? 'es', [
    'footer',
    'nav'
  ])
  const { redirect: redirectParam, email: emailParam, bf, ...rest } = query
  const redirect =
    redirectParam && typeof redirectParam === 'string' ? redirectParam : null
  const email = emailParam && typeof emailParam === 'string' ? emailParam : null
  const backoff =
    bf && typeof bf === 'string' && !isNaN(parseInt(bf)) ? parseInt(bf) : null

  const client = getApolloClient(context)
  const { isAuthenticated } = await verifyAuthenticatedUser(client)

  if (isAuthenticated) {
    return {
      props: {},
      redirect: {
        destination: redirect ?? RedirectUrls.Home,
        permanent: false
      }
    }
  }

  if (backoff && backoff > 5) {
    loggerServer.error('Backoff limit reached', { backoff })
    return {
      props: {},
      redirect: {
        destination: '/500',
        permanent: false
      }
    }
  }

  const queryString = mapToQueryString(rest)
  return {
    props: {
      ...translations,
      redirect: redirect ? `${redirect}?bf=${backoff}${queryString}` : null,
      email,
      backoff
    }
  }
}
